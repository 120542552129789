<template>
  <app-container icon="import_contacts" colWidth="col-12 col-lg-10" v-if="!isTextLoading">
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="doc-row">
        <div class="doc-row__separator col-12">
          <p>{{ texts.section_1.title }}</p>
        </div>
        <div class="row doc-align">
          <div class="col-12 col-lg-4" v-for="img in texts.section_1.documents" :key="img.img">
            <img class="cursor-pointer" :src="require(`@/assets/img/${img.img}`)" />
            <p class="doc-align-name"> {{ img.name }}</p>
          </div>
        </div>
      </div>
      <div class="doc-row">
        <div class="doc-row__separator col-12">
          <p>{{ texts.section_2.title }}</p>
        </div>
        <div class="doc-row__link col-12">
          <a :href="texts.section_2.link.url">{{ texts.section_2.link.text }}</a>
        </div>
      </div>
    </template>
  </app-container>
</template>

<script>
// TODO: Need to add docs & put imgs in config
// Replace imgs with better quality

import useTexts from '@/mixins/useTexts';

export default {
  name: 'DocumentationPage',
  mixins: [useTexts],
  data() {
    return {
      page: 'documentation',
    };
  },
};
</script>

<style lang="scss">
.doc-row {
  margin-bottom: 2rem;
  text-align: left;
  &__separator {
    background-color: $dark;
    color: $light;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    p {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
  &__link {
    padding: 0rem 2rem;
    a {
      font-size: 18px;
      text-decoration: underline !important;
    }
  }
  &__img-row {
    .col-12 {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 300px;
      }
    }
  }
}

.doc-align {
  text-align: -webkit-center;
}

.doc-align-name {
  padding-top: 1rem;
  justify-content: center;
}
</style>
